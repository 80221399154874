import * as faceapi from "face-api.js";

export const FaceApiFactory = (function () {
  // Models are served by `parcel-plugin-static-files-copy`.
  // `parcel-plugin-static-files-copy` does not support serving assets without a file extension.
  // Therefore we need to modify these assets in static/models and append them with a `.bin` file extension.
  // https://github.com/tensorflow/tfjs/issues/924#issuecomment-458599827
  const MODEL_URL = "models";

  let instance;
  return {
    getInstance: function () {
      if (instance == null) {
        Promise.all([
          faceapi.loadSsdMobilenetv1Model(MODEL_URL),
          faceapi.loadFaceLandmarkModel(MODEL_URL),
        ]).then((api) => {
          instance = faceapi;

          // We have an instance ready. Let's run a detection in the background to load all data into cache.
          // This will dramatically speed up the eye detection for the user when uploading a first image.
          let image = new Image();
          image.src = "img/guy.jpg";
          instance.detectSingleFace(image).withFaceLandmarks().run();
        });
      }
      return instance;
    },
  };
})();

export function detect(image) {
  return new Promise((resolve, reject) => {
    FaceApiFactory.getInstance()
      .detectSingleFace(
        image,
        new faceapi.SsdMobilenetv1Options({ scoreThreshold: 0.8 })
      )
      .withFaceLandmarks()
      .then((face) => {
        if (face?.landmarks) {
          const leftEye = calculateEye(face.landmarks.getLeftEye());
          const rightEye = calculateEye(face.landmarks.getRightEye());

          resolve({ leftEye, rightEye });
        } else {
          resolve();
        }
      });
  });
}

function calculateEye(eye) {
  const x = eye.map((eye) => eye.x);
  const xMax = Math.max.apply(Math, x);
  const xMin = Math.min.apply(Math, x);
  const xSize = xMax - xMin;

  const y = eye.map((eye) => eye.y);
  const yMax = Math.max.apply(Math, y);
  const yMin = Math.min.apply(Math, y);
  const ySize = yMax - yMin;

  return {
    x: xMin + xSize / 2,
    y: yMin + ySize / 2,
    size: xSize + ySize,
  };
}
