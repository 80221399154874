import loadImage from "./imageShape";

export function removeCoins(leftCoin, rightCoin) {

  if(leftCoin) {
    leftCoin.destroy();

  }
  if(rightCoin) {
    rightCoin.destroy();
  }
}

/**
 *  Add coins to the stage
 * @param {string} src Source url
 * @param {Konva.Layer} layer Layer
 * @param {number} size Size
 * @returns {Promise<Konva.Image[]>}
 */

export function addCoins(src, layer, x, y, xSize, x2, y2, ySize) {
  return Promise.all([loadCoin(src.left, x ? x : 200, y ? y : 200, xSize).then(({image}) => {
    layer.add(image);
    return image;
  }),
  loadCoin(src.right, x2 ? x2 : 350, y2 ? y2 : 200, ySize).then(({image}) => {
    layer.add(image);
    return image;
  })]);
}

/**
 * Laad coins by given source
 * @param {string} src Source url
 * @param {number} x X position
 * @param {number} y Y position
 * @returns {Promise<Konva.Image>}
 */

export function loadCoin(src, x, y, size) {
  return loadImage(src, true, size, size, x, y, true).then((image) => {
    return image;
  }).catch(err => {
    console.log("err", err);
  })
}
