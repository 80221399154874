import Konva from "konva";
import loadImage from "./imageShape";
import guy from "../img/guy.jpg";
import btcguy from "../img/bg-btc.jpg";
import ethguy from "../img/bg-eth.jpg";
import bnbguy from "../img/bg-bnb.jpg";
import trxguy from "../img/bg-trx.jpg";
import dogeguy from "../img/bg-doge.jpg";
import maneguy from "../img/eyes/mane/body-bg.png";
import bgo1t from "../img/bg-o1t.jpg";
import sunguy from "../img/bg-sun.jpg";
import bttguy from "../img/eyes/btt/bg-btt.jpg";
import nftguy from "../img/eyes/nft/bg-nft.jpg";
import winguy from "../img/eyes/win/bg-win.jpg";
import diceguy from "../img/eyes/dice/bg-dice.jpg";
import rakeguy from "../img/eyes/rake/bg-rake.jpg";
import liveguy from "../img/eyes/live/bg-live.jpg";
import klvguy from "../img/eyes/klv/bg-klv.jpg";
import jstguy from "../img/eyes/jst/bg-jst.jpg";
import shibaguy from "../img/eyes/shiba/bg-shiba.jpg";
import noleguy from "../img/bg-nole.jpg";
import milkguy from "../img/eyes/milk/bg-milk.jpg";
import lumiguy from "../img/eyes/lumi/body-bg.jpg";
import bbtguy from "../img/eyes/bbt/body-bg.jpg";
import cyfmguy from "../img/eyes/cyfm/body-bg.jpg";
import mftuguy from "../img/eyes/mftu/body-bg.jpg";
import wnhguy from "../img/eyes/wnh/body-bg.jpg";
import psrsguy from "../img/eyes/psrs/body-bg.jpg";
import tntguy from "../img/eyes/tnt/body-bg.jpg";
import ktyguy from "../img/eyes/kty/body-bg.jpg";
import turuguy from "../img/eyes/turu/body-bg.jpg";
import twjguy from "../img/eyes/twj/body-bg.png";
import zlfguy from "../img/eyes/zlf/body-bg.jpg";
import cubieguy from "../img/eyes/cubie/body-bg.jpg";
import activguy from "../img/eyes/activ/body-bg.png";
import kodxguy from "../img/eyes/kodx/body-bg.png";
import zenithguy from "../img/eyes/zenith/body-bg.png";
import obtcguy from "../img/eyes/obtc/body-bg.png";
import trexguy from "../img/eyes/trex/body-bg.png";
import tbtguy from "../img/eyes/tbt/body-bg.png";
import cccguy from "../img/eyes/ccc/body-bg.png";

import eye1 from "../img/eyes/jm/eye1.png";
import eye2 from "../img/eyes/jm/eye2.png";
import eye3 from "../img/eyes/jm/eye3.png";
import eye4 from "../img/eyes/jm/eye4.png";
import eye5 from "../img/eyes/jm/eye5.png";
import eye6 from "../img/eyes/jm/eye6.png";

import trxeye1 from "../img/eyes/trx/eye1.png";
import trxeye2 from "../img/eyes/trx/eye2.png";
import trxeye3 from "../img/eyes/trx/eye3.png";
import trxeye4 from "../img/eyes/trx/eye4.png";
import trxeye5 from "../img/eyes/trx/eye5.png";
import trxeye6 from "../img/eyes/trx/eye6.png";

import btceye1 from "../img/eyes/btc/eye1.png";
import btceye2 from "../img/eyes/btc/eye2.png";
import btceye3 from "../img/eyes/btc/eye3.png";
import btceye4 from "../img/eyes/btc/eye4.png";
import btceye5 from "../img/eyes/btc/eye5.png";
import btceye6 from "../img/eyes/btc/eye6.png";

import etheye1 from "../img/eyes/eth/eye1.png";
import etheye2 from "../img/eyes/eth/eye2.png";
import etheye3 from "../img/eyes/eth/eye3.png";
import etheye4 from "../img/eyes/eth/eye4.png";
import etheye5 from "../img/eyes/eth/eye5.png";
import etheye6 from "../img/eyes/eth/eye6.png";

import bnbeye1 from "../img/eyes/bnb/eye1.png";
import bnbeye2 from "../img/eyes/bnb/eye2.png";
import bnbeye3 from "../img/eyes/bnb/eye3.png";
import bnbeye4 from "../img/eyes/bnb/eye4.png";
import bnbeye5 from "../img/eyes/bnb/eye5.png";
import bnbeye6 from "../img/eyes/bnb/eye6.png";

import maneeye1 from "../img/eyes/mane/eye1.png";
import maneeye2 from "../img/eyes/mane/eye2.png";
import maneeye3 from "../img/eyes/mane/eye3.png";
import maneeye4 from "../img/eyes/mane/eye4.png";
import maneeye5 from "../img/eyes/mane/eye5.png";
import maneeye6 from "../img/eyes/mane/eye6.png";

import dogeeye1 from "../img/eyes/doge/eye1.png";
import dogeeye2 from "../img/eyes/doge/eye2.png";
import dogeeye3 from "../img/eyes/doge/eye3.png";
import dogeeye4 from "../img/eyes/doge/eye4.png";
import dogeeye5 from "../img/eyes/doge/eye5.png";
import dogeeye6 from "../img/eyes/doge/eye6.png";

import o1teye1Left from "../img/eyes/o1t/eye1_left.svg";
import o1teye1Right from "../img/eyes/o1t/eye1_right.svg";

import suneye1 from "../img/eyes/sun/eye1.png";
import suneye2 from "../img/eyes/sun/eye2.png";
import suneye3 from "../img/eyes/sun/eye3.png";
import suneye4 from "../img/eyes/sun/eye4.png";
import suneye5 from "../img/eyes/sun/eye5.png";
import suneye6 from "../img/eyes/sun/eye6.png";

import btteye1 from "../img/eyes/btt/btt-eye1.png";
import btteye2 from "../img/eyes/btt/btt-eye2.png";
import btteye3 from "../img/eyes/btt/btt-eye3.png";
import btteye4 from "../img/eyes/btt/btt-eye4.png";
import btteye5 from "../img/eyes/btt/btt-eye5.png";
import btteye6 from "../img/eyes/btt/btt-eye6.png";

import nfteye1 from "../img/eyes/nft/nft-eye1.png";
import nfteye2 from "../img/eyes/nft/nft-eye2.png";
import nfteye3 from "../img/eyes/nft/nft-eye3.png";
import nfteye4 from "../img/eyes/nft/nft-eye4.png";
import nfteye5 from "../img/eyes/nft/nft-eye5.png";
import nfteye6 from "../img/eyes/nft/nft-eye6.png";

import wineye1 from "../img/eyes/win/win-eye1.png";
import wineye2 from "../img/eyes/win/win-eye2.png";
import wineye3 from "../img/eyes/win/win-eye3.png";
import wineye4 from "../img/eyes/win/win-eye4.png";
import wineye5 from "../img/eyes/win/win-eye5.png";
import wineye6 from "../img/eyes/win/win-eye6.png";

import diceeye1 from "../img/eyes/dice/dice-eye1.png";
import diceeye2 from "../img/eyes/dice/dice-eye2.png";
import diceeye3 from "../img/eyes/dice/dice-eye3.png";
import diceeye4 from "../img/eyes/dice/dice-eye4.png";
import diceeye5 from "../img/eyes/dice/dice-eye5.png";
import diceeye6 from "../img/eyes/dice/dice-eye6.png";

import rakeeye1 from "../img/eyes/rake/rake-eye1.png";
import rakeeye2 from "../img/eyes/rake/rake-eye2.png";
import rakeeye3 from "../img/eyes/rake/rake-eye3.png";
import rakeeye4 from "../img/eyes/rake/rake-eye4.png";
import rakeeye5 from "../img/eyes/rake/rake-eye5.png";
import rakeeye6 from "../img/eyes/rake/rake-eye6.png";

import liveeye1 from "../img/eyes/live/live-eye1.png";
import liveeye2 from "../img/eyes/live/live-eye2.png";
import liveeye3 from "../img/eyes/live/live-eye3.png";
import liveeye4 from "../img/eyes/live/live-eye4.png";
import liveeye5 from "../img/eyes/live/live-eye5.png";
import liveeye6 from "../img/eyes/live/live-eye6.png";

import klveye1 from "../img/eyes/klv/klv-eye1.png";
import klveye2 from "../img/eyes/klv/klv-eye2.png";
import klveye3 from "../img/eyes/klv/klv-eye3.png";
import klveye4 from "../img/eyes/klv/klv-eye4.png";
import klveye5 from "../img/eyes/klv/klv-eye5.png";
import klveye6 from "../img/eyes/klv/klv-eye6.png";

import jsteye1 from "../img/eyes/jst/jst-eye1.png";
import jsteye2 from "../img/eyes/jst/jst-eye2.png";
import jsteye3 from "../img/eyes/jst/jst-eye3.png";
import jsteye4 from "../img/eyes/jst/jst-eye4.png";
import jsteye5 from "../img/eyes/jst/jst-eye5.png";
import jsteye6 from "../img/eyes/jst/jst-eye6.png";

import shibaeye1 from "../img/eyes/shiba/shiba-eye1.png";
import shibaeye2 from "../img/eyes/shiba/shiba-eye2.png";
import shibaeye3 from "../img/eyes/shiba/shiba-eye3.png";
import shibaeye4 from "../img/eyes/shiba/shiba-eye4.png";
import shibaeye5 from "../img/eyes/shiba/shiba-eye5.png";
import shibaeye6 from "../img/eyes/shiba/shiba-eye6.png";

import noleeye1 from "../img/eyes/nole/nole-eye1.png";
import noleeye2 from "../img/eyes/nole/nole-eye2.png";
import noleeye3 from "../img/eyes/nole/nole-eye3.png";
import noleeye4 from "../img/eyes/nole/nole-eye4.png";
import noleeye5 from "../img/eyes/nole/nole-eye5.png";
import noleeye6 from "../img/eyes/nole/nole-eye6.png";

import milkeye1 from "../img/eyes/milk/milk-eye1.png";
import milkeye2 from "../img/eyes/milk/milk-eye2.png";
import milkeye3 from "../img/eyes/milk/milk-eye3.png";
import milkeye4 from "../img/eyes/milk/milk-eye4.png";
import milkeye5 from "../img/eyes/milk/milk-eye5.png";
import milkeye6 from "../img/eyes/milk/milk-eye6.png";

import lumieye1 from "../img/eyes/lumi/lumi-eye1.png";
import lumieye2 from "../img/eyes/lumi/lumi-eye2.png";
import lumieye3 from "../img/eyes/lumi/lumi-eye3.png";
import lumieye4 from "../img/eyes/lumi/lumi-eye4.png";
import lumieye5 from "../img/eyes/lumi/lumi-eye5.png";
import lumieye6 from "../img/eyes/lumi/lumi-eye6.png";

import bbteye1 from "../img/eyes/bbt/bbt-eye1.png";
import bbteye2 from "../img/eyes/bbt/bbt-eye2.png";
import bbteye3 from "../img/eyes/bbt/bbt-eye3.png";
import bbteye4 from "../img/eyes/bbt/bbt-eye4.png";
import bbteye5 from "../img/eyes/bbt/bbt-eye5.png";
import bbteye6 from "../img/eyes/bbt/bbt-eye6.png";

import cyfmeye1 from "../img/eyes/cyfm/cyfm-eye1.png";
import cyfmeye2 from "../img/eyes/cyfm/cyfm-eye2.png";
import cyfmeye3 from "../img/eyes/cyfm/cyfm-eye3.png";
import cyfmeye4 from "../img/eyes/cyfm/cyfm-eye4.png";
import cyfmeye5 from "../img/eyes/cyfm/cyfm-eye5.png";
import cyfmeye6 from "../img/eyes/cyfm/cyfm-eye6.png";

import mftueye1 from "../img/eyes/mftu/mftu-eye1.png";
import mftueye2 from "../img/eyes/mftu/mftu-eye2.png";
import mftueye3 from "../img/eyes/mftu/mftu-eye3.png";
import mftueye4 from "../img/eyes/mftu/mftu-eye4.png";
import mftueye5 from "../img/eyes/mftu/mftu-eye5.png";
import mftueye6 from "../img/eyes/mftu/mftu-eye6.png";

import wnheye1 from "../img/eyes/wnh/eye1.png";
import wnheye2 from "../img/eyes/wnh/eye2.png";
import wnheye3 from "../img/eyes/wnh/eye3.png";
import wnheye4 from "../img/eyes/wnh/eye4.png";
import wnheye5 from "../img/eyes/wnh/eye5.png";
import wnheye6 from "../img/eyes/wnh/eye6.png";

import psrseye1 from "../img/eyes/psrs/eye1.png";
import psrseye2 from "../img/eyes/psrs/eye2.png";
import psrseye3 from "../img/eyes/psrs/eye3.png";
import psrseye4 from "../img/eyes/psrs/eye4.png";
import psrseye5 from "../img/eyes/psrs/eye5.png";
import psrseye6 from "../img/eyes/psrs/eye6.png";

import ktyeye1 from "../img/eyes/kty/kty-eye1.png";
import ktyeye2 from "../img/eyes/kty/kty-eye2.png";
import ktyeye3 from "../img/eyes/kty/kty-eye3.png";
import ktyeye4 from "../img/eyes/kty/kty-eye4.png";
import ktyeye5 from "../img/eyes/kty/kty-eye5.png";
import ktyeye6 from "../img/eyes/kty/kty-eye6.png";

import tnteye1 from "../img/eyes/tnt/eye1.png";
import tnteye2 from "../img/eyes/tnt/eye2.png";
import tnteye3 from "../img/eyes/tnt/eye3.png";
import tnteye4 from "../img/eyes/tnt/eye4.png";
import tnteye5 from "../img/eyes/tnt/eye5.png";
import tnteye6 from "../img/eyes/tnt/eye6.png";

import turueye1 from "../img/eyes/turu/eye1.png";
import turueye2 from "../img/eyes/turu/eye2.png";
import turueye3 from "../img/eyes/turu/eye3.png";
import turueye4 from "../img/eyes/turu/eye4.png";
import turueye5 from "../img/eyes/turu/eye5.png";
import turueye6 from "../img/eyes/turu/eye6.png";

import twjeye1 from "../img/eyes/twj/eye1.png";
import twjeye2 from "../img/eyes/twj/eye2.png";
import twjeye3 from "../img/eyes/twj/eye3.png";
import twjeye4 from "../img/eyes/twj/eye4.png";
import twjeye5 from "../img/eyes/twj/eye5.png";
import twjeye6 from "../img/eyes/twj/eye6.png";

import zlfeye1 from "../img/eyes/zlf/eye1.png";
import zlfeye2 from "../img/eyes/zlf/eye2.png";
import zlfeye3 from "../img/eyes/zlf/eye3.png";
import zlfeye4 from "../img/eyes/zlf/eye4.png";
import zlfeye5 from "../img/eyes/zlf/eye5.png";
import zlfeye6 from "../img/eyes/zlf/eye6.png";

import cubieeye1 from "../img/eyes/cubie/eye1.png";
import cubieeye2 from "../img/eyes/cubie/eye2.png";
import cubieeye3 from "../img/eyes/cubie/eye3.png";
import cubieeye4 from "../img/eyes/cubie/eye4.png";
import cubieeye5 from "../img/eyes/cubie/eye5.png";
import cubieeye6 from "../img/eyes/cubie/eye6.png";

import activeye1 from "../img/eyes/activ/eye1.png";
import activeye2 from "../img/eyes/activ/eye2.png";
import activeye3 from "../img/eyes/activ/eye3.png";
import activeye4 from "../img/eyes/activ/eye4.png";
import activeye5 from "../img/eyes/activ/eye5.png";
import activeye6 from "../img/eyes/activ/eye6.png";

import kodxeye1 from "../img/eyes/kodx/eye1.png";
import kodxeye2 from "../img/eyes/kodx/eye2.png";
import kodxeye3 from "../img/eyes/kodx/eye3.png";
import kodxeye4 from "../img/eyes/kodx/eye4.png";
import kodxeye5 from "../img/eyes/kodx/eye5.png";
import kodxeye6 from "../img/eyes/kodx/eye6.png";

import zenitheye1 from "../img/eyes/zenith/eye1.png";
import zenitheye2 from "../img/eyes/zenith/eye2.png";
import zenitheye3 from "../img/eyes/zenith/eye3.png";
import zenitheye4 from "../img/eyes/zenith/eye4.png";
import zenitheye5 from "../img/eyes/zenith/eye5.png";
import zenitheye6 from "../img/eyes/zenith/eye6.png";

import obtceye1 from "../img/eyes/obtc/eye1.png";
import obtceye2 from "../img/eyes/obtc/eye2.png";
import obtceye3 from "../img/eyes/obtc/eye3.png";
import obtceye4 from "../img/eyes/obtc/eye4.png";
import obtceye5 from "../img/eyes/obtc/eye5.png";
import obtceye6 from "../img/eyes/obtc/eye6.png";

import trexeye1 from "../img/eyes/trex/eye1.png";
import trexeye2 from "../img/eyes/trex/eye2.png";
import trexeye3 from "../img/eyes/trex/eye3.png";
import trexeye4 from "../img/eyes/trex/eye4.png";
import trexeye5 from "../img/eyes/trex/eye5.png";
import trexeye6 from "../img/eyes/trex/eye6.png";

import tbteye1 from "../img/eyes/tbt/eye1.png";
import tbteye2 from "../img/eyes/tbt/eye2.png";
import tbteye3 from "../img/eyes/tbt/eye3.png";
import tbteye4 from "../img/eyes/tbt/eye4.png";
import tbteye5 from "../img/eyes/tbt/eye5.png";
import tbteye6 from "../img/eyes/tbt/eye6.png";

import ccceye1 from "../img/eyes/ccc/eye1.png";
import ccceye2 from "../img/eyes/ccc/eye2.png";
import ccceye3 from "../img/eyes/ccc/eye3.png";
import ccceye4 from "../img/eyes/ccc/eye4.png";
import ccceye5 from "../img/eyes/ccc/eye5.png";
import ccceye6 from "../img/eyes/ccc/eye6.png";

let bgArray = {
  jm:guy,
  trx:trxguy,
  btc:btcguy,
  bnb:bnbguy,
  eth:ethguy,
  mane:maneguy,
  ccc:cccguy,
  doge:dogeguy,
  o1t: bgo1t,
  sun: sunguy,
  btt: bttguy,
  nft: nftguy,
  win: winguy,
  dice: diceguy,
  rake: rakeguy,
  live: liveguy,
  klv: klvguy,
  jst: jstguy,
  shiba: shibaguy,
  nole: noleguy,
  milk: milkguy,
  lumi: lumiguy,
  bbt: bbtguy,
  cyfm: cyfmguy,
  mftu: mftuguy,
  wnh: wnhguy,
  psrs: psrsguy,
  kty: ktyguy,
  tnt: tntguy,
  turu: turuguy,
  twj: twjguy,
  zlf: zlfguy,
  cubie: cubieguy,
  activ: activguy,
  kodx: kodxguy,
  zenith: zenithguy,
  obtc: obtcguy,
  trex: trexguy,
  tbt: tbtguy
}
let coinArray = {
  jm:[{left: eye1, right: eye1}, {left: eye2, right: eye2}, {left: eye3, right: eye3}, {left: eye4, right: eye4}, {left: eye5, right: eye5}, {left: eye6, right: eye6}],
  trx:[{left: trxeye1, right: trxeye1}, {left: trxeye2, right: trxeye2}, {left: trxeye3, right: trxeye3}, {left: trxeye4, right: trxeye4}, {left: trxeye5, right: trxeye5}, {left: trxeye6, right: trxeye6}],
  btc:[{left: btceye1, right: btceye1}, {left: btceye2, right: btceye2}, {left: btceye3, right: btceye3}, {left: btceye4, right: btceye4}, {left: btceye5, right: btceye5}, {left: btceye6, right: btceye6}],
  bnb:[{left: bnbeye1, right: bnbeye1}, {left: bnbeye2, right: bnbeye2}, {left: bnbeye3, right: bnbeye3}, {left: bnbeye4, right: bnbeye4}, {left: bnbeye5, right: bnbeye5}, {left: bnbeye6, right: bnbeye6}],
  eth:[{left: etheye1, right: etheye1}, {left: etheye2, right: etheye2}, {left: etheye3, right: etheye3}, {left: etheye4, right: etheye4}, {left: etheye5, right: etheye5}, {left: etheye6, right: etheye6}],
  mane:[{left: maneeye1, right: maneeye1}, {left: maneeye2, right: maneeye2}, {left: maneeye3, right: maneeye3}, {left: maneeye4, right: maneeye4}, {left: maneeye5, right: maneeye5}, {left: maneeye6, right: maneeye6}],
  ccc:[{left: ccceye1, right: ccceye1}, {left: ccceye2, right: ccceye2}, {left: ccceye3, right: ccceye3}, {left: ccceye4, right: ccceye4}, {left: ccceye5, right: ccceye5}, {left: ccceye6, right: ccceye6}],
  doge:[{left: dogeeye1, right: dogeeye1}, {left: dogeeye2, right: dogeeye2}, {left: dogeeye3, right: dogeeye3}, {left: dogeeye4, right: dogeeye4}, {left: dogeeye5, right: dogeeye5}, {left: dogeeye6, right: dogeeye6}],
  o1t:[{left: o1teye1Left, right: o1teye1Right, customPosition: { rightX:0.79, rightY:0.35, rightRotation: 13, leftX:0.41, leftY:0.8, leftRotation: 170}}],
  sun:[{left: suneye1, right: suneye1}, {left: suneye2, right: suneye2}, {left: suneye3, right: suneye3}, {left: suneye4, right: suneye4}, {left: suneye5, right: suneye5}, {left: suneye6, right: suneye6}],
  btt:[{left: btteye1, right: btteye1}, {left: btteye2, right: btteye2}, {left: btteye3, right: btteye3}, {left: btteye4, right: btteye4}, {left: btteye5, right: btteye5}, {left: btteye6, right: btteye6}],
  nft:[{left: nfteye1, right: nfteye1}, {left: nfteye2, right: nfteye2}, {left: nfteye3, right: nfteye3}, {left: nfteye4, right: nfteye4}, {left: nfteye5, right: nfteye5}, {left: nfteye6, right: nfteye6}],
  win:[{left: wineye1, right: wineye1}, {left: wineye2, right: wineye2}, {left: wineye3, right: wineye3}, {left: wineye4, right: wineye4}, {left: wineye5, right: wineye5}, {left: wineye6, right: wineye6}],
  dice:[{left: diceeye1, right: diceeye1}, {left: diceeye2, right: diceeye2}, {left: diceeye3, right: diceeye3}, {left: diceeye4, right: diceeye4}, {left: diceeye5, right: diceeye5}, {left: diceeye6, right: diceeye6}],
  rake:[{left: rakeeye1, right: rakeeye1}, {left: rakeeye2, right: rakeeye2}, {left: rakeeye3, right: rakeeye3}, {left: rakeeye4, right: rakeeye4}, {left: rakeeye5, right: rakeeye5}, {left: rakeeye6, right: rakeeye6}],
  live:[{left: liveeye1, right: liveeye1}, {left: liveeye2, right: liveeye2}, {left: liveeye3, right: liveeye3}, {left: liveeye4, right: liveeye4}, {left: liveeye5, right: liveeye5}, {left: liveeye6, right: liveeye6}],
  klv:[{left: klveye1, right: klveye1}, {left: klveye2, right: klveye2}, {left: klveye3, right: klveye3}, {left: klveye4, right: klveye4}, {left: klveye5, right: klveye5}, {left: klveye6, right: klveye6}],
  jst:[{left: jsteye1, right: jsteye1}, {left: jsteye2, right: jsteye2}, {left: jsteye3, right: jsteye3}, {left: jsteye4, right: jsteye4}, {left: jsteye5, right: jsteye5}, {left: jsteye6, right: jsteye6}],
  shiba:[{left: shibaeye1, right: shibaeye1}, {left: shibaeye2, right: shibaeye2}, {left: shibaeye3, right: shibaeye3}, {left: shibaeye4, right: shibaeye4}, {left: shibaeye5, right: shibaeye5}, {left: shibaeye6, right: shibaeye6}],
  nole:[{left: noleeye1, right: noleeye1}, {left: noleeye2, right: noleeye2}, {left: noleeye3, right: noleeye3}, {left: noleeye4, right: noleeye4}, {left: noleeye5, right: noleeye5}, {left: noleeye6, right: noleeye6}],
  milk:[{left: milkeye1, right: milkeye1}, {left: milkeye2, right: milkeye2}, {left: milkeye3, right: milkeye3}, {left: milkeye4, right: milkeye4}, {left: milkeye5, right: milkeye5}, {left: milkeye6, right: milkeye6}],
  lumi:[{left: lumieye1, right: lumieye1}, {left: lumieye2, right: lumieye2}, {left: lumieye3, right: lumieye3}, {left: lumieye4, right: lumieye4}, {left: lumieye5, right: lumieye5}, {left: lumieye6, right: lumieye6}],
  bbt:[{left: bbteye1, right: bbteye1}, {left: bbteye2, right: bbteye2}, {left: bbteye3, right: bbteye3}, {left: bbteye4, right: bbteye4}, {left: bbteye5, right: bbteye5}, {left: bbteye6, right: bbteye6}],
  cyfm:[{left: cyfmeye1, right: cyfmeye1}, {left: cyfmeye2, right: cyfmeye2}, {left: cyfmeye3, right: cyfmeye3}, {left: cyfmeye4, right: cyfmeye4}, {left: cyfmeye5, right: cyfmeye5}, {left: cyfmeye6, right: cyfmeye6}],
  mftu:[{left: mftueye1, right: mftueye1}, {left: mftueye2, right: mftueye2}, {left: mftueye3, right: mftueye3}, {left: mftueye4, right: mftueye4}, {left: mftueye5, right: mftueye5}, {left: mftueye6, right: mftueye6}],
  wnh:[{left: wnheye1, right: wnheye1}, {left: wnheye2, right: wnheye2}, {left: wnheye3, right: wnheye3}, {left: wnheye4, right: wnheye4}, {left: wnheye5, right: wnheye5}, {left: wnheye6, right: wnheye6}],
  psrs:[{left: psrseye1, right: psrseye1}, {left: psrseye2, right: psrseye2}, {left: psrseye3, right: psrseye3}, {left: psrseye4, right: psrseye4}, {left: psrseye5, right: psrseye5}, {left: psrseye6, right: psrseye6}],
  kty:[{left: ktyeye1, right: ktyeye1}, {left: ktyeye2, right: ktyeye2}, {left: ktyeye3, right: ktyeye3}, {left: ktyeye4, right: ktyeye4}, {left: ktyeye5, right: ktyeye5}, {left: ktyeye6, right: ktyeye6}],
  tnt:[{left: tnteye1, right: tnteye1}, {left: tnteye2, right: tnteye2}, {left: tnteye3, right: tnteye3}, {left: tnteye4, right: tnteye4}, {left: tnteye5, right: tnteye5}, {left: tnteye6, right: tnteye6}],
  turu:[{left: turueye1, right: turueye1}, {left: turueye2, right: turueye2}, {left: turueye3, right: turueye3}, {left: turueye4, right: turueye4}, {left: turueye5, right: turueye5}, {left: turueye6, right: turueye6}],
  twj:[{left: twjeye1, right: twjeye1}, {left: twjeye2, right: twjeye2}, {left: twjeye3, right: twjeye3}, {left: twjeye4, right: twjeye4}, {left: twjeye5, right: twjeye5}, {left: twjeye6, right: twjeye6}],
  zlf:[{left: zlfeye1, right: zlfeye1}, {left: zlfeye2, right: zlfeye2}, {left: zlfeye3, right: zlfeye3}, {left: zlfeye4, right: zlfeye4}, {left: zlfeye5, right: zlfeye5}, {left: zlfeye6, right: zlfeye6}],
  cubie:[{left: cubieeye1, right: cubieeye1}, {left: cubieeye2, right: cubieeye2}, {left: cubieeye3, right: cubieeye3}, {left: cubieeye4, right: cubieeye4}, {left: cubieeye5, right: cubieeye5}, {left: cubieeye6, right: cubieeye6}],
  activ:[{left: activeye1, right: activeye1}, {left: activeye2, right: activeye2}, {left: activeye3, right: activeye3}, {left: activeye4, right: activeye4}, {left: activeye5, right: activeye5}, {left: activeye6, right: activeye6}],
  kodx:[{left: kodxeye1, right: kodxeye1}, {left: kodxeye2, right: kodxeye2}, {left: kodxeye3, right: kodxeye3}, {left: kodxeye4, right: kodxeye4}, {left: kodxeye5, right: kodxeye5}, {left: kodxeye6, right: kodxeye6}],
  zenith:[{left: zenitheye1, right: zenitheye1}, {left: zenitheye2, right: zenitheye2}, {left: zenitheye3, right: zenitheye3}, {left: zenitheye4, right: zenitheye4}, {left: zenitheye5, right: zenitheye5}, {left: zenitheye6, right: zenitheye6}],
  obtc:[{left: obtceye1, right: obtceye1}, {left: obtceye2, right: obtceye2}, {left: obtceye3, right: obtceye3}, {left: obtceye4, right: obtceye4}, {left: obtceye5, right: obtceye5}, {left: obtceye6, right: obtceye6}],
  trex:[{left: trexeye1, right: trexeye1}, {left: trexeye2, right: trexeye2}, {left: trexeye3, right: trexeye3}, {left: trexeye4, right: trexeye4}, {left: trexeye5, right: trexeye5}, {left: trexeye6, right: trexeye6}],
  tbt:[{left: tbteye1, right: tbteye1}, {left: tbteye2, right: tbteye2}, {left: tbteye3, right: tbteye3}, {left: tbteye4, right: tbteye4}, {left: tbteye5, right: tbteye5}, {left: tbteye6, right: tbteye6}],

};

import {addCoins, removeCoins} from "./coins";
import {downloadURI} from "./export";
import * as face from './face';


let coinElements,
    otherCurrenciesContainer,
    moreButton,
    imageChanged = false,
    leftCoin,
    rightCoin,
    userImage,
    selectedCoin = eye2,
    scaleLeft = 1.5,
    scaleRight = 1.5,
    rotationLeft = 0,
    rotationRight = 0,
    canvas,
    x = 280, y = 92, size = 60,
    x2 = 320, y2 = 88, size2 = 60;

let fileLabel = document.getElementById('fileLabel');

let mainWidth = Math.min(600, window.screen.width);
if(mainWidth < 600) {
  x = x * (mainWidth/600);
  x2 = x2 * (mainWidth/600);
  y = y * (mainWidth/600);
  y2 = y2 * (mainWidth/600);
}

// first we need to create a stage
var stage = new Konva.Stage({
  container: 'container',   // id of container <div>
  width: mainWidth,
  height: mainWidth
});

/**
 *
 * @type {Konva.Layer}
 */
var layer = new Konva.Layer();

// add the layer to the stage
stage.add(layer);

// draw the image
layer.draw();

document.getElementById("file").addEventListener("change", (event) => {
  if(event.target.files.length > 0){
    imageChanged = true;
    let src = URL.createObjectURL(event.target.files[0]);
    removeCoins();
    fileLabel.style.position = "static";
    fileLabel.style.top = "0";
    fileLabel.style.left = "0";
    loadImage(src, false, mainWidth, mainWidth, 0, 0, false, true).then(
      ({ image, width, height}) => {


        if (userImage) {
          userImage.destroy();
        }
        userImage = image;
        stage.width(width);
        stage.height(height);
        layer.add(image);

        face.detect(stage.toCanvas()).then(({ leftEye, rightEye }) => {
          x = leftEye.x;
          y = leftEye.y;
          //size = leftEye.size;

          x2 = rightEye.x;
          y2 = rightEye.y;
          //size2 = rightEye.size;
          removeCoins(leftCoin, rightCoin);
          addCoins(selectedCoin, layer, x, y, 60, x2, y2, 60).then(
            (coins) => {
              leftCoin = coins[0];
              rightCoin = coins[1];


              leftCoin.scale({
                x: scaleLeft / (600/canvas.offsetWidth),
                y: scaleLeft / (600/canvas.offsetWidth),
              });
              rightCoin.scale({
                x: scaleRight / (600/canvas.offsetWidth),
                y: scaleRight / (600/canvas.offsetWidth),
              });
              rotationLeft = 0;
              rotationRight = 0;
              leftCoin.rotate(rotationLeft);
              rightCoin.rotate(rotationRight);
            }
          );
        })
        .catch(() => {
          x = 280;
          y = 92;
          //size = 60;

          x2 = 320;
          y2 = 88;
          //size2 = 60;
          removeCoins(leftCoin, rightCoin);
          addCoins(selectedCoin, layer, x, y, 60, x2, y2, 60).then(
            (coins) => {

              leftCoin = coins[0];
              rightCoin = coins[1];

              leftCoin.scale({ x: scaleLeft / (600/canvas.offsetWidth), y: scaleLeft / (600/canvas.offsetWidth)});
              rightCoin.scale({ x: scaleRight / (600/canvas.offsetWidth), y: scaleRight / (600/canvas.offsetWidth)});
              leftCoin.rotate(rotationLeft);
              rightCoin.rotate(rotationRight);
            }
          );
        });
      }
    );
  }

});

function addListeners() {


  canvas = document.querySelector('#container canvas');

  coinElements = document.querySelectorAll('.coins');
  let coins = document.querySelectorAll('.coins .coin');
  for(let i=0; i<coins.length; i++) {

    coins[i].addEventListener("click", (ev) => {

        removeCoins(leftCoin, rightCoin);
        leftCoin = null;
        rightCoin = null;

        selectedCoin = coinArray[ev.target.getAttribute('cur')][ev.target.getAttribute('index')];

        addCoins(selectedCoin, layer, x, y, 60, x2, y2, 60).then(onCoinAdded);
    });
  }

  var scaleSlider = document.getElementById("scaleSlider");
  var scaleLeftSlider = document.getElementById("scaleLeftSlider");
  var scaleRightSlider = document.getElementById("scaleRightSlider");
  var rotationSlider = document.getElementById("rotationSlider");
  var rotationLeftSlider = document.getElementById("rotationLeftSlider");
  var rotationRightSlider = document.getElementById("rotationRightSlider");


  scaleSlider.ondblclick = function() {
    scaleSlider.style.display = "none"
    scaleLeftSlider.style.display = "block"
    scaleRightSlider.style.display = "block"
  };

  scaleLeftSlider.ondblclick = function() {
    scaleSlider.style.display = "block"
    scaleLeftSlider.style.display = "none"
    scaleRightSlider.style.display = "none"
  };

  scaleRightSlider.ondblclick = function() {
    scaleSlider.style.display = "block"
    scaleLeftSlider.style.display = "none"
    scaleRightSlider.style.display = "none"
  };

  scaleSlider.oninput = function() {

    scaleRight = (this.value/100)+0.5;
    scaleLeft = (this.value/100)+0.5;

    leftCoin.scale({x:scaleLeft / (600/canvas.offsetWidth), y:scaleLeft / (600/canvas.offsetWidth)});
    rightCoin.scale({x:scaleRight / (600/canvas.offsetWidth), y:scaleRight / (600/canvas.offsetWidth)});

    scaleLeftSlider.value = this.value;
    scaleRightSlider.value = this.value;
  }

  scaleLeftSlider.oninput = function() {


    scaleLeft = (this.value/100)+0.5;

    leftCoin.scale({x:scaleLeft / (600/canvas.offsetWidth), y:scaleLeft / (600/canvas.offsetWidth)});

    scaleSlider.value = this.value;
  }

  scaleRightSlider.oninput = function() {
    scaleRight = (this.value/100)+0.5;
    rightCoin.scale({x:scaleRight / (600/canvas.offsetWidth), y:scaleRight / (600/canvas.offsetWidth)});

    scaleSlider.value = this.value;
  }

  rotationSlider.ondblclick = function() {
    rotationSlider.style.display = "none"
    rotationLeftSlider.style.display = "block"
    rotationRightSlider.style.display = "block"
  };

  rotationLeftSlider.ondblclick = function() {
    rotationSlider.style.display = "block"
    rotationLeftSlider.style.display = "none"
    rotationRightSlider.style.display = "none"
  };

  rotationRightSlider.ondblclick = function() {
    rotationSlider.style.display = "block"
    rotationLeftSlider.style.display = "none"
    rotationRightSlider.style.display = "none"
  };

  rotationSlider.oninput = function() {
    rotationLeft = rotationRight = this.value;
    leftCoin.rotation(rotationLeft);
    rightCoin.rotation(rotationRight);

    rotationLeftSlider.value = this.value;
    rotationRightSlider.value = this.value;
  }

  rotationLeftSlider.oninput = function() {
    rotationLeft = this.value;
    leftCoin.rotation(rotationLeft);

    rotationSlider.value = this.value;
  }

  rotationRightSlider.oninput = function() {
    rotationRight = this.value;
    rightCoin.rotation(rotationRight);

    rotationSlider.value = this.value;
  }

  document.getElementById('save').addEventListener(
    'click',
    function () {
      var dataURL = stage.toDataURL({ pixelRatio: 3 });
      downloadURI(dataURL, 'coinEyes.png');
    },
    false
  );

  window.addEventListener('load', function () {
    face.FaceApiFactory.getInstance();
  });

  window.addEventListener('hashchange', function(ev) {
      changeCurrency(document.location.hash.replace('#', ''));
      window.ga('event', 'click', {
        'event_category':'Currencies',
        'event_action':'Change',
        'event_label': location.hash.replace('#','')
      });
  });
  otherCurrenciesContainer = document.getElementById('otherCurrencies');
  moreButton = document.getElementById('more');
  more.addEventListener('click', function () {
    otherCurrenciesContainer.style.display = 'flex';
    moreButton.style.visibility = 'hidden';
  });

}

addListeners();


changeCurrency(document.location.hash.replace('#', ''));




function changeCurrency(currency) {

  if(currency) {
    let other = document.querySelector('#otherCurrencies #' + currency);
    if(other) {
      otherCurrenciesContainer.style.display = 'flex';
      moreButton.style.visibility = 'hidden';
    }
  }


  for (let i = 0; i < coinElements.length; i++) {
    coinElements[i].classList.remove('selected');
  }

  let selectedCurrency = document.querySelector("." + currency + '-coins');
  if (selectedCurrency) {
    selectedCurrency.classList.add('selected');
  } else {
    window.document.location.hash = "#jm";
    return;
  }
  if (coinArray[currency][2]) {
    selectedCoin = coinArray[currency][2];
  } else {
    selectedCoin = coinArray[currency][0];
  }



  if (!imageChanged) {

    loadImage(bgArray[currency], false, mainWidth, mainWidth, 0, 0, false, true).then(
      ({image, width, height}) => {


      if (userImage) {
        userImage.destroy();
      }
      userImage = image;
      layer.add(image);
      stage.width(width);
      stage.height(height);
      removeCoins(leftCoin, rightCoin);

      let leftX = selectedCoin.customPosition ? parseInt(canvas.offsetWidth * selectedCoin.customPosition.leftX) : x;
      let leftY = selectedCoin.customPosition ? parseInt(canvas.offsetHeight * selectedCoin.customPosition.leftY) : y;
      let rightX = selectedCoin.customPosition ? parseInt(canvas.offsetWidth * selectedCoin.customPosition.rightX) : x2;
      let rightY = selectedCoin.customPosition ? parseInt(canvas.offsetHeight * selectedCoin.customPosition.rightY) : y2;

      addCoins(selectedCoin, layer, leftX, leftY, 60, rightX, rightY, 60).then(onCoinAdded);
    });
  } else {


    let leftX = selectedCoin.customPosition ? parseInt(canvas.offsetWidth * selectedCoin.customPosition.leftX) : x;
    let leftY = selectedCoin.customPosition ? parseInt(canvas.offsetHeight * selectedCoin.customPosition.leftY) : y;
    let rightX = selectedCoin.customPosition ? parseInt(canvas.offsetWidth * selectedCoin.customPosition.rightX) : x2;
    let rightY = selectedCoin.customPosition ? parseInt(canvas.offsetHeight * selectedCoin.customPosition.rightY) : y2;

    removeCoins(leftCoin, rightCoin);
    addCoins(selectedCoin, layer, leftX, leftY, 60, rightX, rightY, 60).then(onCoinAdded);
  }

}

function onCoinAdded(coins) {
  leftCoin = coins[0];
  rightCoin = coins[1];

  leftCoin.scale({x:scaleLeft / (600/canvas.offsetWidth), y: scaleLeft / (600/canvas.offsetWidth)});
  rightCoin.scale({x:scaleRight / (600/canvas.offsetWidth), y: scaleLeft / (600/canvas.offsetWidth)});
  let leftRotation = selectedCoin.customPosition ? selectedCoin.customPosition.leftRotation : rotationLeft;
  let rightRotation = selectedCoin.customPosition ? selectedCoin.customPosition.rightRotation : rotationRight;
  leftCoin.rotate(leftRotation);
  rightCoin.rotate(rightRotation);
  leftCoin.on('dragend', function () {
    x = leftCoin.x();
    y = leftCoin.y();
  });
  rightCoin.on('dragend', function () {
    x2 = rightCoin.x();
    y2 = rightCoin.y();
  });
}
