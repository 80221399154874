import Konva from "konva";

/**
 * Load an image
 * @param {string} src Image url
 * @param {boolean} draggable Is Draggable
 * @param {number} width Width
 * @param {number} height Height
 * @param {number} x X position
 * @param {number} y Y postition
 * @returns {Promise<Konva.Image>}
 */
function loadImage(src, draggable, width, height, x, y, center, fit) {

  return new Promise((resolve, reject) => {
    var imageObj = new Image();
    imageObj.onload = function () {


      if(fit) {
        if(imageObj.naturalWidth == imageObj.naturalHeight) {

        } else if(imageObj.naturalWidth >= imageObj.naturalHeight) {
          height = imageObj.naturalHeight*(width/imageObj.naturalWidth);
        } else {
          console.log(imageObj.naturalHeight, imageObj.naturalWidth);
          console.log(imageObj.naturalHeight, imageObj.naturalWidth);
          width = imageObj.naturalWidth*(width/imageObj.naturalHeight);
        }
      }
      let img = new Konva.Image({
        x: x,
        y: y,
        image: imageObj,
        width: width,
        height: height,
        draggable: draggable,
        offsetX: center ? width/2 : 0,
        offsetY: center ? height/2 : 0
      });
      resolve({image:img, width, height});
    }
    imageObj.onerror = function (err) {
      reject(err);
    }
    imageObj.src = src;
  })

}


export default loadImage;
